/*

***

Создание задачи:
src:\components\TaskCreateDrawer.tsx

Редактирование задачи (при щелчке на задачу в карточке Совещания и меню "Задачи") - 
src:\components\TaskEditDrawer.tsx 

В Совещаниях/карточка совещания/Пункт"Задачи":   
src:\containers\TaskIdeasRules\Components\TaskAccordeon.tsx

В меню "Задачи" - аккордион задачи:    
src:\containers\TaskIdeasRules\Components\TaskAccordeonMenuTask.tsx  

Пункт меню "Задачи" (список задач), включая заголовок и пагинацию:
src:\containers\TaskIdeasRules\index.tsx 

Пункт меню "Совещания". Список совещаний:
src:\containers\workflow\index.tsx 

Аккордион совещания:
src:\containers\workflow\components\accordion.tsx 

Создание совещания:
src:\containers\workflow\CreateMeeting 

Редактирование совещания:
src:\components\MeetingEditDrawer.tsx  

Меню "Сотрудники":
src:\conteiners\departmens

Запросы к api:
src:\api

*/

import { Box } from "@mui/material";
import React from "react";
import RouterWrapper from "../routes/RouterWrapper";
import { AppStoreProvider } from "../store";
import Theme from "./Theme";
import UserDataWrapper from "./UserDataWrapper";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { rootReducer } from "./rootReducer";
import { rootSaga } from "./rootSaga";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { useStateContext } from "../store/useStateContext";
import { useState } from "react";
import { WorkflowCreateParams } from "../types/Workflow";
import { nanoid } from "nanoid";
import { MeetingFormatEnum } from "../types/Meeting";
import { MeetingData, MeetingsListData } from "../types/Meeting";
import {  WorkflowListData } from "../types/workflow";

const composeEnhancers =
  (window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] as typeof compose) || compose;

const sagaMiddleware = createSagaMiddleware();

export const urlApp: string = process.env.REACT_APP
  ? `https://${process.env.REACT_APP}`
  : "https://em.dev.easy-task.ru";

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

const initialModelTask: WorkflowCreateParams = {
  assignee: { id: 0, name: "", image: { url: "", name: "" }, surname: "" },
  description: "",
  endAt: new Date(Date.now() + 3600000 * 24),
  name: "",
  responsible: { id: 1, name: "Админ", image: null, surname: "Тестовый" },
  startAt: new Date(),
  timing: new Date(2000, 1, 1, 0, 0, 0),
  type: "task",
  _id: "",
  notice: "",
  meeting: "",
  meetingParticipants: [],
  tag: {id: 1, name: 'Быстрые задачи'} 
};

sagaMiddleware.run(rootSaga);

const App: React.FC = () => {
  // Тут вносятся данные, общие для всей программы

   // установка колонок, которые показываются в списке совещаний
  const [selectColumns, setSelectColumns] = useState([
    "status",
    "name",
    "format",
    "place",
    "project_id",
    "initiator_id",
    "chairman_id",
    "members",
  ]);
 // установка колонок, которые показываются в списке задач
  const [selectColumnsTask, setSelectColumnsTask] = useState([
    "author",
    "name",
    "status",
    "start_at",
    "endpoint",
    "initiator_id",
  ]);

  // открывать/закрывать окно редактирования задачи или нет
  const [editTaskDrawer, setEditTaskDrawer] = useState<boolean>(false);
 // открывать/закрывать значок- список колонок
  const [filterColumn, setFilterColumn] = useState<boolean>(false);
  // данные задачи
  const [modelTask, setModelTask] = useState<WorkflowCreateParams>(initialModelTask);

    // Фильтры совещаний

    // открывать или нет фильтр
    const [openFilterMeets, setOpenFilterMeets] = useState<boolean>(false);

    // содержимое фильтра
    const [textMeetsFilter, setTextMeetsFilter] = useState(""); 

    // Дата начала
    const [startAt, setStartAt] = useState("");
 
    // format
  const [format, setFormat] = useState<MeetingFormatEnum>(MeetingFormatEnum.ONLINE);
  
    //  Наименование
    const [selectedName, setSelectedName] = useState(""); 
  
    // Председатель
    const [selectedChairman, setSelectedChairman] = useState({
      id: nanoid(), value: "", userId: "", inputValue: "" });
  
   
    // Секретарь
    const [selectedSecretary, setSelectedSecretary] = useState({
      id: nanoid(), value: "", userId: "", inputValue: "" });
    
    // Остальные участники совещания  
    const [usersArr, setUsersArr] = useState([
        { id: nanoid(), value: "", userId: "", inputValue: "" },
      ]);
  
    // Статусы  
    // На согласовании
    const [selectedOn_approval, setSelectedOn_approval] = useState(false);
    // Согласовано  
    const [selectedApproved, setSelectedApproved] = useState(false);
    // Подтверждено
    const [selectedConfirmed, setSelectedConfirmed] = useState(false);
    // В процессе
    const [selectedIn_progress, setSelectedIn_progress] = useState(false);
    // Состоялось
    const [selectedFinished, setSelectedFinished] = useState(false);
    // Отменено
    const [selectedCanceled, setSelectedCanceled] = useState(false);
    // Удобное время
    const [selectedUpdate_date, setSelectedUpdate_date] = useState(false);
    // Отказ от участия
    const [selectedDeclined, setSelectedDeclined] = useState(false);

    // Фильтры задач 
    // открывать или нет фильтр задач
    const [openFilterTasks, setOpenFilterTasks] = useState<boolean>(false);
    // Содержимое фильтра задач
    const [textTasksFilter, setTextTasksFilter] = useState(""); 
    
    // Дата начала
    const [startAtTasks, setStartAtTasks] = useState("");
 
    // Деадлайн
    const [deadlineTasks, setDeadlineTasks] = useState("");

    //  Наименование
    const [selectedNameTasks, setSelectedNameTasks] = useState(""); 

    // Автор
    const [selectedAuthorTasks, setSelectedAuthorTasks] = useState({
    id: nanoid(), value: "", userId: "", inputValue: "", });

 
    // Исполнитель
    const [selectedExecutorTasks, setSelectedExecutorTasks] = useState({
    id: nanoid(), value: "", userId: "", inputValue: "", });

    // Список всех совещаний
    const [meetingsList, setMeetingsList] = useState<MeetingsListData>();

    // Список всех задач в меню "Задачи"
    const [tasksList, setTasksList] = useState<WorkflowListData>();
   
    
  // Тут надо повторить данные, общие для всей программы
  let context = {
    editTaskDrawer, setEditTaskDrawer, // открывать/закрывать окно редактирования задачи или нет
    modelTask, setModelTask,         // данные задачи
    filterColumn, setFilterColumn,   // открывать/закрывать значок- список колонок
    selectColumns, // колонки, которые показываются в списке совещаний
    setSelectColumns,  // установка колонок, которые показываются в списке совещаний
    selectColumnsTask, // колонки, которые показываются в списке задач
    setSelectColumnsTask, //  установка колонок, которые показываются в списке задач

    // Фильтры совещаний
    openFilterMeets,setOpenFilterMeets, // открывать/закрывать окно фильтра списка совещаний
    textMeetsFilter, // содержимое текста запроса для фильтра по совещаниям
    setTextMeetsFilter, // установить текст запроса для фильтра по совещаниям
    startAt, setStartAt,  // Дата начала     
    format, setFormat, // format
    selectedName, setSelectedName, //  Наименование 
    selectedChairman, setSelectedChairman,   // Председатель  
    selectedSecretary, setSelectedSecretary,  // Секретарь
    usersArr, setUsersArr, // остальные участники  
    // Статусы      
    selectedOn_approval, setSelectedOn_approval, // На согласовании
    selectedApproved, setSelectedApproved, // Согласовано   
    selectedConfirmed, setSelectedConfirmed,  // Подтверждено   
    selectedIn_progress, setSelectedIn_progress, // В процессе    
    selectedFinished, setSelectedFinished, // Состоялось
    selectedCanceled, setSelectedCanceled, // Отменено   
    selectedUpdate_date, setSelectedUpdate_date, // Удобное время    
    selectedDeclined, setSelectedDeclined, // Отказ от участия
    // Фильтры задач
    openFilterTasks, setOpenFilterTasks,  // открывать/закрывать окно фильтра списка задач
    textTasksFilter, setTextTasksFilter,   // Содержимое фильтра задач    
    startAtTasks, setStartAtTasks, // Дата начала
    deadlineTasks, setDeadlineTasks, // Деадлайн
    selectedNameTasks, setSelectedNameTasks,  //  Наименование
    selectedAuthorTasks, setSelectedAuthorTasks, // Автор
    selectedExecutorTasks, setSelectedExecutorTasks, // Исполнитель
     meetingsList, setMeetingsList, // список всех совещаний
     tasksList, setTasksList  // Список всех задач в меню "Задачи"
    
  }

  return (
    <Box>
      <AppStoreProvider>
        <Provider store={store}>
          <useStateContext.Provider value={context}>
            <Theme>
              <UserDataWrapper>
                <RouterWrapper />
              </UserDataWrapper>
            </Theme>
          </useStateContext.Provider>
        </Provider>
      </AppStoreProvider>
    </Box>
  );
};

export default App;
